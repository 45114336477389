import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center text-sm font-bold m-4" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.formatClass)
    }, _toDisplayString(_ctx.$t("label.event")) + ": ", 3),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.myEventId) = $event)),
      id: _ctx.testId,
      class: "text-sm rounded-md py-1 font-semibold text-gray-800 border border-gray-800 text-center"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportingEvents, (event) => {
        return (_openBlock(), _createElementBlock("option", {
          value: event.eventId,
          key: event.eventId
        }, _toDisplayString(_ctx.eventName(event.eventId)), 9, _hoisted_3))
      }), 128))
    ], 8, _hoisted_2), [
      [_vModelSelect, _ctx.myEventId]
    ])
  ]))
}