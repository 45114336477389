
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { User } from "firebase/auth";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "@/utils/firebase";
import {
  nounsMapConfig,
  featureConfig,
  supportingEvents,
} from "@/config/project";
import {
  defineComponent,
  ref,
  watch,
  computed,
  WritableComputedRef,
} from "vue";
import router from "@/router";
import { PhotoPubData } from "@/models/photo";
import { getLocaleName } from "@/i18n/utils";
import { eventName } from "@/utils/utils";
import { photoInfoUpdated, photoDeleted } from "@/utils/functions";
import EventSelector from "./EventSelector.vue";
import InputText from "./InputText.vue";
import IconButton from "./IconButton.vue";
import LabelText from "./LabelText.vue";

export default defineComponent({
  components: {
    EventSelector,
    InputText,
    IconButton,
    LabelText,
  },
  emits: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const user = computed<User>(() => store.state.user);
    watch(user, () => {
      checkUser();
    });
    const checkUser = () => {
      isWalletUser.value =
        store.state.userType == "wallet" &&
        clickedPhoto.value?.uid == user.value?.uid;
      isOwner.value = clickedPhoto.value?.uid == user.value?.uid;
    };
    const isOwner = ref(false);
    const isWalletUser = ref(false);
    const isEditInfo = ref(false);
    const isDelete = ref(false);
    const processing = ref("");
    const photoTitle = ref<string>("");
    watch(photoTitle, () => {
      //console.log(photoTitle.value);
      processing.value = "";
    });
    const photoEventId = ref<number>(0);
    watch(photoEventId, () => {
      //console.log(photoEventId.value);
      processing.value = "";
    });
    const clickedPhoto: WritableComputedRef<PhotoPubData | undefined> =
      computed({
        get: () => store.state.clickedPhoto as PhotoPubData,
        set: (val) => store.commit("setClickedPhoto", val),
      });
    watch(clickedPhoto, () => {
      checkUser();
      if (clickedPhoto.value) {
        photoTitle.value = clickedPhoto.value.title
          ? clickedPhoto.value.title
          : "";
        photoEventId.value = clickedPhoto.value.eventId
          ? clickedPhoto.value.eventId
          : 0;
        console.log(photoTitle.value, photoEventId.value);
      }
    });
    const close = () => {
      console.log(router);
      isEditInfo.value = false;
      isDelete.value = false;
      clickedPhoto.value = undefined;
      if (route.params.eventId) {
        router.push({
          name: getLocaleName(router, "eventmap"),
          params: { eventId: route.params.eventId },
        });
      } else {
        router.push("../map");
      }
    };
    const savePhotoInfo = async () => {
      if (!clickedPhoto.value) {
        console.error("wrong sequence");
        return;
      }
      processing.value = "saving";
      const photoId = clickedPhoto.value.photoId;
      const title = photoTitle.value;
      const eventId = photoEventId.value;
      console.log({ title, eventId });

      try {
        //user data master  photo meta data 更新
        await updateDoc(
          doc(db, `users/${user.value.uid}/public_photos/${photoId}`),
          {
            title,
            eventId,
            updatedAt: serverTimestamp(),
          }
        );
        // backend へ 全体共有情報更新依頼
        const ret = await photoInfoUpdated({ photoId, title, eventId });
        console.log(ret);
        processing.value = "saveComplete";
        //close();
      } catch (e: unknown) {
        console.error(e);
      }
    };
    const deletePhoto = async () => {
      if (!clickedPhoto.value) {
        console.error("wrong sequence");
        return;
      }
      processing.value = "deleting";
      const photoId = clickedPhoto.value.photoId;
      console.log({ photoId }, "delete");

      try {
        //user data master photo meta data 更新
        await updateDoc(
          doc(db, `users/${user.value.uid}/public_photos/${photoId}`),
          {
            deletedFlag: true,
            updatedAt: serverTimestamp(),
          }
        );
        // backend へ 全体共有情削除依頼
        const ret = await photoDeleted({ photoId });
        console.log(ret);
        processing.value = "deleteComplete";
      } catch (e: unknown) {
        console.error(e);
      }
    };
    const nftRequest = () => {
      store.commit("setNftRequestPhoto", clickedPhoto.value);
    };
    const openTweetPopup = (photoId: string) => {
      const url =
        "https://twitter.com/intent/tweet?url=https://" +
        nounsMapConfig.hostName +
        "/p/" +
        photoId;
      const width = 400,
        height = 300;
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                      width=${width},height=${height},left=${
        (window.screen.width - width) / 2
      },top=${(window.screen.height - height) / 2}`;

      window.open(url, url, params);
    };
    return {
      nounsMapConfig,
      featureConfig,
      clickedPhoto,
      isOwner,
      isWalletUser,
      isEditInfo,
      isDelete,
      photoTitle,
      photoEventId,
      supportingEvents,
      processing,
      close,
      savePhotoInfo,
      deletePhoto,
      nftRequest,
      openTweetPopup,
      eventName,
    };
  },
});
