
import { defineComponent, ref } from "vue";
import router from "@/router";
import { getLocaleName } from "@/i18n/utils";

export default defineComponent({
  emits: {},
  setup() {
    const isShow = ref();
    const open = () => {
      isShow.value = true;
    };
    const close = () => {
      isShow.value = false;
    };
    const gotoLogin = () => {
      isShow.value = false;
      router.push({
        name: getLocaleName(router, "user"),
      });
    };
    return {
      isShow,
      open,
      close,
      gotoLogin,
    };
  },
});
