
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  emits: ["update:text"],
  props: {
    text: {
      type: String,
      default: "",
    },
    formatClass: {
      type: String,
      default: "text-white",
    },
    testId: String,
    label: String,
  },
  setup(props, context) {
    const textRef = ref();
    const getText = () => {
      return textRef.value.value;
    };
    const changed = () => {
      context.emit("update:text", textRef.value.value);
    };
    onMounted(() => {
      textRef.value.value = props.text;
    });
    return {
      textRef,
      changed,
      getText,
    };
  },
});
