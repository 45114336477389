import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-center items-center text-sm font-bold m-4" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.formatClass)
    }, _toDisplayString(_ctx.$t(_ctx.label)) + ": ", 3),
    _createElementVNode("input", {
      type: "text",
      id: _ctx.testId,
      ref: "textRef",
      maxlength: "128",
      minlength: "1",
      class: "text-sm rounded-md py-1 font-semibold text-gray-800 border border-gray-800 text-center",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changed && _ctx.changed(...args)))
    }, null, 40, _hoisted_2)
  ]))
}