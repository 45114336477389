
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    testId: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    formatClass: {
      type: String,
      default: "",
    },
  },
});
