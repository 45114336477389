import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-center mx-auto px-2 py-4"
}
const _hoisted_2 = {
  key: 0,
  ref: "resized",
  width: "600",
  height: "600"
}
const _hoisted_3 = { class: "sr-only" }
const _hoisted_4 = {
  key: 1,
  class: "shrink-0 py-2"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isShow)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        false
          ? (_openBlock(), _createElementBlock("canvas", _hoisted_2, null, 512))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("message.selectImage")), 1),
        _createElementVNode("input", {
          type: "file",
          ref: "fileInput",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pickFile && _ctx.pickFile(...args))),
          class: "text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-1 file:text-sm file:font-semibold file:bg-white file:text-gray-800 hover:file:bg-gray-100"
        }, null, 544),
        (_ctx.previewImage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                ref: "imageRef",
                class: "h-40 object-cover rounded-md",
                src: _ctx.previewImage,
                onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onImageLoad && _ctx.onImageLoad(...args))),
                alt: "selected photo"
              }, null, 40, _hoisted_5)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}