import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-row justify-center m-4" }
const _hoisted_2 = { class: "text-sm font-bold m-2" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.formatClass)
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.label)) + ":", 1),
      _createElementVNode("span", { id: _ctx.testId }, _toDisplayString(_ctx.text), 9, _hoisted_3)
    ], 2)
  ]))
}