import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "text-5xl material-icons hover:animate-pulse mr-2" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "text-large",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args))),
    id: _ctx.testId
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.formatClass)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.animate)
      }, [
        _createElementVNode("i", _hoisted_2, _toDisplayString(_ctx.icon), 1)
      ], 2),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.label)), 1))
        : _createCommentVNode("", true)
    ], 2)
  ], 8, _hoisted_1))
}