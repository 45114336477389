
import { defineComponent, ref, toRefs } from "vue";
import IconButton from "./IconButton.vue";
import LabelText from "./LabelText.vue";

export default defineComponent({
  components: {
    IconButton,
    LabelText,
  },
  emits: {
    started: Number,
    updated: Number,
    stopped: null,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 1,
    },
    start: {
      type: Number,
      deafult: 1,
    },
    repeat: {
      type: Boolean,
      default: true,
    },
    wait: {
      type: Number,
      default: 2,
    },
  },

  setup(props, context) {
    const { total } = toRefs(props);
    const playbackState = ref("stopped");
    const playbackIndex = ref(1);
    let timerObj: ReturnType<typeof setTimeout>;
    const playback = () => {
      timerObj = setInterval(() => {
        count();
      }, props.wait * 1000);
      console.log("started", { timerObj });
      playbackState.value = "playing";
      context.emit("started", playbackIndex.value);
      update();
    };
    const count = () => {
      playbackIndex.value++;
      if (total.value < playbackIndex.value) {
        complete();
      } else {
        update();
      }
    };
    const uncount = () => {
      playbackIndex.value--;
      if (playbackIndex.value < 1) {
        playbackIndex.value = total.value;
      } else {
        update();
      }
    };
    const pause = () => {
      console.log("paused", { timerObj });
      clearInterval(timerObj);
      playbackState.value = "paused";
    };
    const stop = () => {
      console.log("stopped", { timerObj });
      clearInterval(timerObj);
      playbackState.value = "stopped";
      context.emit("stopped");
    };

    const complete = () => {
      playbackIndex.value = 1;
      update();
    };
    const update = () => {
      //console.log(playbackIndex.value);
      context.emit("updated", playbackIndex.value);
    };
    const next = () => {
      count();
    };
    const previous = () => {
      uncount();
    };

    return {
      playbackState,
      playbackIndex,
      playback,
      stop,
      pause,
      next,
      previous,
    };
  },
});
