
import { defineComponent, PropType } from "vue";
// import firebaseApp from '@/src/main.js'
import {
  getAuth,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
} from "firebase/auth";
import { UserData } from "@/components/NounsUser.vue";

export default defineComponent({
  props: {
    user: Object as PropType<UserData>,
  },
  setup() {
    const signin = () => {
      const provider = new TwitterAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then(() => {
          // The signed-in user info.
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode + "@@@" + errorMessage);
        });
    };
    const signout = () => {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          console.log("logout correctly");
        })
        .catch(() => {
          alert("Logout fail");
        });
    };
    return {
      signin,
      signout,
    };
  },
});
