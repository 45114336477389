
import { defineComponent, ref } from "vue";
import PhotoSelect from "@/components/PhotoSelect.vue";

export default defineComponent({
  props: {
    photoSelect: PhotoSelect,
  },
  setup(props) {
    const isShow = ref();
    const open = () => {
      isShow.value = true;
    };
    const gotoPhotoSelect = () => {
      isShow.value = false;
      props.photoSelect?.show();
    };
    return {
      isShow,
      open,
      gotoPhotoSelect,
    };
  },
});
