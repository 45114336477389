
import { defineComponent } from "vue";

export default defineComponent({
  emits: {
    clicked: null,
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    testId: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    formatClass: {
      type: String,
      default: "",
    },
    animate: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const clicked = () => {
      context.emit("clicked");
    };
    return {
      clicked,
    };
  },
});
