
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { ethers } from "ethers";
import { User } from "firebase/auth";
import { db } from "@/utils/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";

import { ContentsContract } from "@/config/project";
import { NftPhoto } from "@/models/photo";
import { photoNFTDownload } from "@/utils/functions";
import { switchNetwork } from "@/utils/MetaMask";
import { shortID } from "@/utils/utils";

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const user = computed<User>(() => store.state.user);
    const errorAccount = ref(false);
    const justMinted = ref(false);
    const nftSyncing = ref(false);

    const nftPhotos = ref<NftPhoto[]>([]);
    const nftOwnPhotos = ref<NftPhoto[]>([]);
    const downloadLink = ref<string>();

    const tokenGate = computed(() => {
      if (!store.state.account) {
        return "noAccount";
      }
      if (parseInt(store.state.chainId) != parseInt(ContentsContract.chainId)) {
        return "invalidNetwork";
      }
      return "valid";
    });
    const switchToValidNetwork = async () => {
      console.log(ContentsContract.chainId);
      await switchNetwork(ContentsContract.chainId);
    };
    const account = computed(() => {
      if (store.state.account) {
        return ethers.utils.getAddress(store.state.account);
      }
      return null;
    });

    onMounted(async () => {
      updateNftPhotos();
    });

    const updateNftPhotos = async () => {
      const lastOwnUpdate = nftOwnPhotos.value.reduce(
        (p, c) => Math.max(p, (c.updatedAt as Timestamp).toMillis()),
        0
      );
      const lastUpdate = nftPhotos.value.reduce(
        (p, c) => Math.max(p, (c.updatedAt as Timestamp).toMillis()),
        0
      );
      const lastTime = Timestamp.fromMillis(
        Math.max(lastOwnUpdate, lastUpdate)
      );
      const q = query(
        collection(db, `nft_photos`),
        where("updatedAt", ">", lastTime),
        where("nounsmapCreated", "==", true)
      );
      const photos = await getDocs(q);
      for (const doc of photos.docs) {
        const nphoto: NftPhoto = doc.data() as NftPhoto;
        if (!nphoto.photoId) {
          console.log("not nounsmap created", doc.id);
          return;
        }
        if (
          account.value &&
          ethers.utils.getAddress(nphoto.owner) === account.value
        ) {
          console.log("new nft own found", nphoto);
          nftOwnPhotos.value.push(nphoto);
        } else {
          console.log("new nft found", nphoto);
          nftPhotos.value.push(nphoto);
        }
      }
    };

    const downloadOriginal = async (_photoId: string) => {
      console.log(user.value);
      if (
        !account.value ||
        account.value !== ethers.utils.getAddress(user.value.uid)
      ) {
        console.log("wrong user", account.value, user.value);
        errorAccount.value = true;
        return;
      }
      const ret: { data: { success: boolean; url: string } } =
        (await photoNFTDownload({ photoId: _photoId })) as {
          data: { success: boolean; url: string };
        };
      downloadLink.value = ret.data.url;
    };

    return {
      nftPhotos,
      nftOwnPhotos,
      downloadLink,
      account,
      errorAccount,
      justMinted,
      tokenGate,
      ContentsContract,
      nftSyncing,
      switchToValidNetwork,
      shortID,
      downloadOriginal,
    };
  },
});
