
import { defineComponent } from "vue";
import { googleSignin } from "../utils/SocialLogin";

export default defineComponent({
  name: "AccountPage",
  setup() {
    return {
      googleSignin: googleSignin(
        () => {
          alert("OK");
        },
        (err) => {
          alert(err.message);
        }
      ),
    };
  },
});
