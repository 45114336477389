
import { supportingEvents } from "@/config/project";
import { defineComponent, watch, ref, toRefs } from "vue";
import { eventName } from "@/utils/utils";

export default defineComponent({
  emits: ["update:eventId"],
  props: {
    eventId: {
      type: Number,
      default: 0,
    },
    testId: {
      type: String,
      default: "postEvent",
    },
    formatClass: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const { eventId } = toRefs(props);
    //this watch needed for the case direct link to event page (event ID updated has delay)
    watch(eventId, () => {
      console.log("prop update", eventId.value);
      myEventId.value = eventId.value;
    });
    const myEventId = ref<number>(props.eventId);
    watch(myEventId, () => {
      //console.log("selected",myEventId.value);
      context.emit("update:eventId", myEventId.value);
    });
    const getEventId = () => {
      return myEventId.value;
    };
    return {
      myEventId,
      supportingEvents,
      getEventId,
      eventName,
    };
  },
});
